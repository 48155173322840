import React from "react";
import styles from "../Home.module.css";
import VideoItem from "./VideoItem";
import ImgItem from "./ImgItem";

const Gallary = () => {
  return (
    <div>
      <div className={styles.row} id={styles.row1}>
        <VideoItem
          videoName={"Jl-Aw-60s"}
          videoId={styles.vidJlAw}
          link={"/projects/jlaw"}
          title={"John Lewis Autumn Winter"}
          directory={"JLAW"}
        />
        <VideoItem
          videoName={"Fl-Summer-Bareall-Final"}
          videoId={styles.vidFlSummer}
          link={`/media/FGC/Fl-Summer-Bareall-Final/Flannels-Group-Chat/?port=true`}
          title={"Flannels Group Chat"}
          directory={"FGC"}
        />
      </div>
      <div className={styles.row} id={styles.row2}>
        <VideoItem
          videoName={"Belenctest2"}
          videoId={styles.vidBalencTest}
          link={"/media/Balenciaga/Belenctest2/Balenciaga/?port=true"}
          title={"Balenciaga"}
          directory={"Balenciaga"}
        />
        <VideoItem
          videoName={"jlwb"}
          videoId={styles.vidjlwb}
          link={"/media/JLWB/jlwb/John-Lewis-Wellbeing/?port=false"}
          title={"John Lewis Wellness"}
          directory={"JLWB"}
        />
        <ImgItem
          imgName={"01525"}
          imgId={styles.img01525}
          link={"/projects/fr"}
          title={"Flannels Reflections"}
          directory={"FR"}
        />
      </div>
      <div className={styles.row} id={styles.row3}>
        <VideoItem
          videoName={"Givenchy"}
          videoId={styles.vidGivenchi}
          link={"/media/Givenchy/Givenchy/Givenchy/?port=false"}
          title={"Givenchy"}
          directory={"Givenchy"}
        />
      </div>
      <div className={styles.row} id={styles.row4}>
        <VideoItem
          videoName={"jl-allsaints2"}
          videoId={styles.vidAllSaints}
          link={"/media/AllSaints/jl-allsaints2/AllSaints/?port=false"}
          title={"AllSaints"}
          directory={"AllSaints"}
        />
        <VideoItem
          videoName={"JLBeauty"}
          videoId={styles.vidJlBeauty}
          link={"/media/JLSOS/JLBeauty/John-Lewis-Sound-of-Skin/?port=false"}
          title={"John Lewis Sound of Skin"}
          directory={"JLSOS"}
        />
      </div>
      <div className={styles.row} id={styles.row5}>
        <VideoItem
          videoName={"NorthenWeather"}
          videoId={styles.vidNorthenWeather}
          link={"/projects/fne"}
          title={"Flannels Northern Escape"}
          directory={"FNE"}
        />
        <VideoItem
          videoName={"PreawZBrights"}
          videoId={styles.vidPreawZBrights}
          link={"/projects/fr"}
          title={"Flannels Reflections"}
          directory={"FR"}
        />
        <VideoItem
          videoName={"Peipei"}
          videoId={styles.vidPeipei}
          link={"/projects/fo"}
          title={"Flannels Overshares"}
          directory={"FO"}
        />
      </div>
      <div className={styles.row} id={styles.row6}>
        <VideoItem
          videoName={"jl-reiss"}
          videoId={styles.vidReiss}
          link={"/media/REISS/jl-reiss/REISS/?port=false"}
          title={"REISS"}
          directory={"REISS"}
        />
      </div>
      <div className={styles.row} id={styles.row7}>
        <VideoItem
          videoName={"24Hoursinmiami"}
          videoId={styles.vid24Miami}
          link={"/media/HBM/24Hoursinmiami/Hugo-Boss-Miami/?port=true"}
          title={"Hugo Boss Miami"}
          directory={"HBM"}
        />
        <VideoItem
          videoName={"Lululemon"}
          videoId={styles.vidLulu}
          link={"/media/LULU/Lululemon/Lululemon/?port=false"}
          title={"Lululemon"}
          directory={"LULU"}
        />
        <VideoItem
          videoName={"Jean-Viviennew-Fog-Reel1"}
          videoId={styles.vidJeanViv}
          link={"/projects/fst"}
          title={"Flannels Self Talk"}
          directory={"FST"}
        />
      </div>
      <div className={styles.row} id={styles.row8}>
        <VideoItem
          videoName={"Alongnight"}
          videoId={styles.vidAln}
          link={"/media/FC/Alongnight/Flannels-Christmas/?port=true"}
          title={"Flannels Christmas"}
          directory={"FC"}
        />
        <ImgItem
          imgName={"Gucci"}
          imgId={styles.imgGucci}
          link={"/projects/JLAW"}
          title={"John Lewis Autumn Winter"}
          directory={"JLAW"}
        />
      </div>
    </div>
  );
};

export default Gallary;
