import React from "react";
import styles from "./fullscreen.module.css";
import global from "../projectPages/global.module.css";
import { useParams, Link, useSearchParams } from "react-router-dom";

const FullScreenVid = () => {
  let { dir, name, title } = useParams();
  let [search] = useSearchParams();
  console.log(search.get("port"));
  return (
    <div className={styles.media_wrapper}>
      <div className={global.nav_brand_wrapper}>
        <Link className={`${global.nav_brand} ${global.nav_a}`} to="/gallary">
          SIMMONS <span>&</span> KAMAZ
        </Link>
      </div>

      <div className={global.nav_info_wrapper}>
        <Link className={`${global.nav_info} ${global.nav_a}`} to="/info">
          INFORMATION
        </Link>
      </div>
      <h2 className={styles.project_title}>{`${title.replaceAll(
        "-",
        " "
      )}`}</h2>
      <video
        src={require(`../../Media/GallaryMedia/${dir}/${name}.mp4`)}
        className={
          search.get("port") === "true"
            ? `${styles.video} ${styles.is_port}`
            : `${styles.video} ${styles.is_lan}`
        }
        controls
        playsInline
      ></video>
    </div>
  );
};

export default FullScreenVid;
