import React from "react";
import global from "../global.module.css";
const ProjectVideo = ({ videoName, isPortrait, directory }) => {
  return (
    <video
      preload="metadata"
      controls={true}
      className={
        isPortrait
          ? `${global.proj_item} ${global.is_portrait}`
          : `${global.proj_item} ${global.is_landscape}`
      }
      playsInline
    >
      <source
        src={require(`../../../Media/GallaryMedia/${directory}/${videoName}.mp4#t=0.001`)}
        type="video/mp4"
      />
    </video>
  );
};

export default ProjectVideo;
