import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styles from "../Home.module.css";

const VideoItem = ({ videoName, videoId, link, title, directory }) => {
  let ref = useRef(true);
  let video = useRef(null);
  let videos = useRef(
    document.getElementsByClassName(`${styles.gallary_video}`)
  );
  let [videoSeen, setVideoSeen] = useState(false);

  let cursor_wrap = useRef(null);
  let cursorTitle = useRef(null);
  let vidLink = useRef(null);

  const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    return partiallyVisible
      ? ((top > 0 && top < innerHeight) ||
          (bottom > 0 && bottom < innerHeight)) &&
          ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
      : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
  };

  useEffect(() => {
    if (ref.current) {
      ref.current = false;
      video.current.volume = 0.2;

      if (elementIsVisibleInViewport(video.current, true)) {
        setVideoSeen(true);
        video.current.style.opacity = 100;
        video.current.play();
      }
    }

    if (videoSeen) {
      video.current.style.opacity = 100;
      video.current.play();
    } else {
      video.current.pause();
    }
  }, [videoSeen, videoName]);

  window.addEventListener("scroll", () => {
    if (video.current) {
      if (videoSeen !== elementIsVisibleInViewport(video.current, true))
        setVideoSeen(elementIsVisibleInViewport(video.current, true));
    }
  });

  return (
    <div className={styles.vid_wrapper}>
      <Link
        ref={vidLink}
        to={link}
        className={styles.video_link}
        onMouseMove={(e) => {
          cursor_wrap.current.style.left = `${e.clientX}px`;
          cursor_wrap.current.style.top = `${e.clientY}px`;
          cursorTitle.current.style.display = "block";
        }}
        onMouseOver={(e) => {
          // e.target.muted = false;
          if (videos !== null) {
            let videosList = videos.current;
            for (let i = 0; i < videosList.length; i++) {
              if (videosList[i].getAttribute("name") !== videoName) {
                videosList[i].pause();
              }
            }
          }
        }}
        onMouseLeave={(e) => {
          // e.target.muted = true;
          if (videos !== null) {
            let videosList = videos.current;
            for (let i = 0; i < videosList.length; i++) {
              if (videosList[i].getAttribute("name") !== videoName) {
                if (elementIsVisibleInViewport(videosList[i], true)) {
                  videosList[i].play();
                }
              }
            }
          }
          cursor_wrap.current.style.left = `0px`;
          cursor_wrap.current.style.top = `0px`;
          cursorTitle.current.style.display = "none";
        }}
      >
        <video
          //   class="video-landscape gallary-video"
          src={require(`../../../Media/GallaryMedia/${directory}/${videoName}.mp4`)}
          ref={video}
          loop
          muted
          id={videoId}
          className={styles.gallary_video}
          name={videoName}
          playsInline
          controls={false}
        ></video>
      </Link>
      {/* Title for vid */}
      <p className={styles.video_title}>{`${title}`}</p>

      {/* Cursor for vid */}
      <div className={styles.cursor_wrap} ref={cursor_wrap}>
        <p className={styles.mouse} ref={cursorTitle}>
          {`${title}`}
        </p>
      </div>
    </div>
  );
};

export default VideoItem;
