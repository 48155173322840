import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../Home.module.css";

const ImgItem = ({ imgName, imgId, link, title, directory }) => {
  let ref = useRef(true);
  let img = useRef(null);
  let cursor_wrap = useRef(null);
  let cursorTitle = useRef(null);

  let [imgSeen, setImgSeen] = useState(false);

  const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    return partiallyVisible
      ? ((top > 0 && top < innerHeight) ||
          (bottom > 0 && bottom < innerHeight)) &&
          ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
      : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
  };

  useEffect(() => {
    if (ref.current) {
      ref.current = false;

      if (elementIsVisibleInViewport(img.current, true)) {
        setImgSeen(true);
        img.current.style.opacity = 100;
      }
    }

    if (imgSeen) {
      img.current.style.opacity = 100;
    }
  }, [imgSeen]);

  window.addEventListener("scroll", () => {
    if (img.current) {
      if (imgSeen !== elementIsVisibleInViewport(img.current, true))
        setImgSeen(elementIsVisibleInViewport(img.current, true));
    }
  });

  return (
    <div className={styles.vid_wrapper}>
      <Link
        className={styles.video_link}
        to={link}
        onMouseMove={(e) => {
          cursor_wrap.current.style.left = `${e.clientX}px`;
          cursor_wrap.current.style.top = `${e.clientY}px`;
          cursorTitle.current.style.display = "block";
        }}
        onMouseLeave={(e) => {
          cursor_wrap.current.style.left = `0px`;
          cursor_wrap.current.style.top = `0px`;
          cursorTitle.current.style.display = "none";
        }}
      >
        <img
          src={require(`../../../Media/GallaryMedia/${directory}/${imgName}.jpg`)}
          id={imgId}
          className={styles.gallary_img}
          alt="Flannels"
          ref={img}
        />
      </Link>

      {/* title for pic */}
      <p className={styles.video_title}>{`${title}`}</p>

      {/* Cursor for pic */}
      <div className={styles.cursor_wrap} ref={cursor_wrap}>
        <p className={styles.mouse} ref={cursorTitle}>
          {`${title}`}
        </p>
      </div>
    </div>
  );
};

export default ImgItem;
