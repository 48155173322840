import React from "react";
import global from "../global.module.css";
const ProjectImg = ({ imgName, directory, isPortrait }) => {
  return (
    <img
      className={
        isPortrait
          ? `${global.proj_item} ${global.is_portrait}`
          : `${global.proj_item} ${global.is_landscape}`
      }
      src={require(`../../../Media/GallaryMedia/${directory}/${imgName}.jpg`)}
      alt={imgName}
    ></img>
  );
};

export default ProjectImg;
