import React, { useEffect, useRef } from "react";

import styles from "./info.module.css";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import ButtonMailTo from "./components/ButtonMailTo";

const Info = () => {
  let ref = useRef(true);
  let video = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current = false;
      if (video.current) {
        video.current.volume = 0.2;
      }
    }
  });
  return (
    <div className={styles.wrapper}>
      <div className={styles.nav_brand_wrapper}>
        <Link className={`${styles.nav_brand} ${styles.nav_a}`} to="/gallary">
          SIMMONS <span>&</span> KAMAZ
        </Link>
      </div>

      {/* <div className={styles.nav_info_wrapper}>
        <Link to="/info" className={`${styles.nav_info} ${styles.nav_a}`}>
          INFORMATION
        </Link>
      </div> */}

      <video
        className={styles.hero}
        src={require("../../Media/info/background1.mp4")}
        autoPlay
        playsInline
        loop
        controls={false}
        ref={video}
        poster={require("../../Media/info/poster.jpg")}
      ></video>
      <div className={styles.caption_wrapper}>
        <h1 className={styles.caption}>
          A London based Duo specialising in <br />
          Film, photography & Creative Direction
        </h1>
      </div>

      <div className={styles.mail_wrapper}>
        <ButtonMailTo
          label="info@simmonsandkamaz.com"
          mailto="mailto:info@simmonsandkamaz.com"
        />
      </div>
      <div className={styles.insta_wrapper}>
        <Link
          to="https://www.instagram.com/simmonsandkamaz/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className={styles.insta} icon={faInstagram} />
        </Link>
      </div>
    </div>
  );
};

export default Info;
