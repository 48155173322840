import React, { useRef, useState, useEffect } from "react";
import styles from "../Home.module.css";
import { Link } from "react-router-dom";
import Gallary from "./Gallary";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeHigh, faVolumeXmark } from "@fortawesome/free-solid-svg-icons";

import { faInstagram } from "@fortawesome/free-brands-svg-icons";

import hero4K from "../../../Media/hero4K.mp4";
import hero1080 from "../../../Media/hero1080p.mp4";

import ButtonMailTo from "../components/ButtonMailTo";

const HomeContent = ({ className }) => {
  let [heroVid, setHeroVid] = useState(hero1080);
  let [vidSet, setVidSet] = useState(false);

  let [heroMuted, setHeroMuted] = useState(true);

  //refs
  let ref = useRef(true);
  let navBrand = useRef(null);
  let navInfo = useRef(null);
  let contentLogo = useRef(null);

  let heroVideo = useRef(null);
  let mutedBtnRef = useRef(null);
  let unmutedBtnRef = useRef(null);

  //useEffect
  useEffect(() => {
    if (ref.current) {
      ref.current = false;
      if (!vidSet) {
        if (window.innerWidth > 1920) {
          setHeroVid(hero4K);
        }
        heroVideo.current.volume = 0.3;
        setVidSet(true);
      }
    }
  }, [vidSet]);

  //event functions
  let changeHeroMuteState = () => {
    setHeroMuted(!heroMuted);
    heroVideo.current.muted = !heroVideo.current.muted;
  };

  window.addEventListener("scroll", () => {
    // check if ref is null
    if (!heroVideo.current) return;
    else {
      if (window.scrollY > heroVideo.current.offsetHeight / 2) {
        navBrand.current.setAttribute(
          "style",
          "opacity: 100; mix-blend-mode: difference"
        );

        navInfo.current.setAttribute("style", "mix-blend-mode: difference");
      } else {
        navBrand.current.style.opacity = 0;
        navBrand.current.setAttribute("style", "mix-blend-mode: normal");
        navInfo.current.setAttribute("style", "mix-blend-mode: normal");
      }
    }
  });

  return (
    <div id="contentContainer" className={className}>
      <div className={styles.nav_brand_wrapper} ref={navBrand}>
        <Link className={`${styles.nav_brand} ${styles.nav_a}`} to="/gallary">
          SIMMONS <span>&</span> KAMAZ
        </Link>
      </div>

      <div className={styles.nav_info_wrapper} ref={navInfo}>
        <Link to="/info" className={`${styles.nav_info} ${styles.nav_a}`}>
          INFORMATION
        </Link>
      </div>

      <section
        className={`${styles.hero} ${styles.logo_hidden}`}
        id="heroSection"
      >
        <div className={styles.hero_video}>
          <video
            id="heroVideo"
            className={styles.hero_vid}
            autoPlay
            loop
            muted
            playsInline
            controls={false}
            src={heroVid}
            ref={heroVideo}
            poster={require("../../../Media/loader1080.jpg")}
          ></video>
          <div
            id="heroMuteBtn"
            className={styles.mute_button}
            onClick={changeHeroMuteState}
          >
            <span
              id="heroMuted"
              className={heroMuted ? null : "hidden"}
              ref={mutedBtnRef}
            >
              <FontAwesomeIcon
                className={styles.mute_icon}
                icon={faVolumeXmark}
              />
            </span>
            <span
              id="heroUnmuted"
              ref={unmutedBtnRef}
              className={heroMuted ? "hidden" : null}
            >
              <FontAwesomeIcon
                className={styles.mute_icon}
                icon={faVolumeHigh}
              />
            </span>
          </div>
        </div>
        <img
          className={styles.content_logo}
          id="contentLogo"
          src={require("../../../Media/logo1.png")}
          alt=""
          ref={contentLogo}
        />
      </section>

      <section id="gallary">
        <Gallary />
      </section>

      <div className={styles.footer}>
        <div className={styles.mail_wrapper}>
          <ButtonMailTo
            label="info@simmonsandkamaz.com"
            mailto="mailto:info@simmonsandkamaz.com"
          />
        </div>
        <div className={styles.insta_wrapper}>
          <Link
            to="https://www.instagram.com/simmonsandkamaz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className={styles.insta} icon={faInstagram} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeContent;
