import React, { useRef } from "react";
import { Link } from "react-router-dom";

import global from "../global.module.css";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
import ProjectVideo from "../components/ProjectVideo";
import ProjectImg from "../components/ProjectImg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const JLAW = () => {
  const videos = useRef(document.getElementsByTagName("video"));

  let pauseVideos = () => {
    for (let i = 0; i < videos.current.length; i++) {
      if (!videos.current[i].paused) {
        videos.current[i].pause();
      }
    }
  };

  return (
    <div className={global.wrapper}>
      <div className={global.nav_brand_wrapper}>
        <Link className={`${global.nav_brand} ${global.nav_a}`} to="/gallary">
          SIMMONS <span>&</span> KAMAZ
        </Link>
      </div>

      <div className={global.nav_info_wrapper}>
        <Link to="/info" className={`${global.nav_info} ${global.nav_a}`}>
          INFORMATION
        </Link>
      </div>

      <h2 className={global.project_title}>John Lewis Autumn Winter</h2>

      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        loop
        pagination={{ clickable: true }}
        onSlideChange={() => pauseVideos()}
        className={global.swiper}
      >
        <SwiperSlide className={global.slide}>
          <ProjectVideo
            videoName={"Jl-Aw-60s"}
            isPortrait={false}
            directory={"JLAW"}
          />
        </SwiperSlide>
        <SwiperSlide className={global.slide}>
          <ProjectImg imgName={"Gucci"} isPortrait={false} directory={"JLAW"} />
        </SwiperSlide>
        <SwiperSlide className={global.slide}>
          <ProjectVideo
            videoName={"Jl-Aw-Mw-V3"}
            isPortrait={false}
            directory={"JLAW"}
          />
        </SwiperSlide>
        <SwiperSlide className={global.slide}>
          <ProjectImg imgName={"radio"} isPortrait={false} directory={"JLAW"} />
        </SwiperSlide>
        <SwiperSlide className={global.slide}>
          <ProjectVideo
            videoName={"Jl-Aw-Running-Reel"}
            isPortrait={true}
            directory={"JLAW"}
          />
        </SwiperSlide>
        <SwiperSlide className={global.slide}>
          <ProjectImg
            imgName={"floral"}
            isPortrait={false}
            directory={"JLAW"}
          />
        </SwiperSlide>
        <SwiperSlide className={global.slide}>
          <ProjectVideo
            videoName={"Jl-Aw-Ww-V3"}
            isPortrait={false}
            directory={"JLAW"}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default JLAW;
