import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/index";
import HomeContent from "./Pages/Home/components/HomeContent";
import JLAW from "./Pages/projectPages/JLAW/index";
import FR from "./Pages/projectPages/FR/index";
import FO from "./Pages/projectPages/FO";
import FNE from "./Pages/projectPages/FNE";
import FST from "./Pages/projectPages/FST";
import Info from "./Pages/Info";
import FullScreenVid from "./Pages/FullScreen/index";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" exact Component={Home}></Route>
        <Route path="/gallary" Component={HomeContent}></Route>
        <Route path="/projects/jlaw" exact Component={JLAW}></Route>
        <Route path="/projects/fr" exact Component={FR}></Route>
        <Route path="/projects/fo" exact Component={FO}></Route>
        <Route path="/projects/fne" exact Component={FNE}></Route>
        <Route path="/projects/fst" exact Component={FST}></Route>
        <Route path="/info" exact Component={Info}></Route>
        <Route
          path="/media/:dir/:name/:title/"
          Component={FullScreenVid}
        ></Route>
      </Routes>
    </Router>
  );
};

export default App;
