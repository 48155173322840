import React, { useState } from "react";
import styles from "./Home.module.css";
import "./HomeId.css";
import logo from "../../Media/logo1.png";
import loaderImg1080 from "../../Media/loader1080.jpg";
import loaderImg1440 from "../../Media/loader1440.jpg";
import loaderImg4k from "../../Media/loader4k.jpg";
import { motion } from "framer-motion";

import { Link } from "react-router-dom";

const Home = () => {
  let [contentActive, setContentActive] = useState(false);
  return (
    <Link to="/gallary">
      <div
        id="loadingContainer"
        className={contentActive ? "hidden" : null}
        onClick={() => {
          setContentActive(!contentActive);
        }}
      >
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 4 }}
          src={logo}
          className={`${styles.logo}`}
          alt=""
        ></motion.img>

        <img
          src={require("../../Media/loader1080.jpg")}
          srcSet={`${loaderImg1080} 1920w, ${loaderImg1440} 2560w, ${loaderImg4k} 3840w`}
          alt=""
          className={styles.loader_img}
        />
        {/* <div className={styles.loader_text_content}>
          <div className={styles.loading}>
            <h3>
              <span id="loadingNumber">100</span>%
            </h3>
          </div>
        </div> */}

        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 4, delay: 0.5 }}
          id="enter"
          className={styles.enter}
        >
          Enter
        </motion.h1>
      </div>
    </Link>
  );
};

export default Home;
